<template>
  <div class="user">
    <div class="new-campaign">
      <div class="container">
        <div class="header-wrap">
          <h2>{{ $t("profil.title") }}</h2>
          <h3 style="float: none; max-width: 800px; text-align: center; margin: 0 auto">
            {{ $t("profil.description") }}
          </h3>
          <br />
        </div>

        <div class="container-small">
          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("profil.dataTitle") }}</h3>
            </header>
            <div class="content-body">
              <div v-if="loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <form @submit="update_user" class="form" method="post">
                  <div class="form-wrap">
                    <label for="company">{{ $t("global.company") }}</label>
                    <input v-model="form_user.company" class="form-input" type="company" name="company" id="company" />
                  </div>

                  <div class="row row-gutter-20">
                    <div class="col-12">
                      <div class="form-wrap">
                        <label for="first_name">{{ $t("global.firstname") }}</label>
                        <input v-model="form_user.first_name" class="form-input" type="first_name" name="first_name" id="first_name" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-wrap">
                        <label for="last_name">{{ $t("global.lastname") }}</label>
                        <input v-model="form_user.last_name" class="form-input" type="last_name" name="last_name" id="last_name" />
                      </div>
                    </div>
                  </div>

                  <div class="row row-gutter-20">
                    <div class="col-16">
                      <div class="form-wrap">
                        <label for="street_name">{{ $t("global.street") }}</label>
                        <input v-model="form_user.street_name" class="form-input" type="street_name" name="street_name" id="street_name" />
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-wrap">
                        <label for="street_number">{{ $t("global.housenumber") }}</label>
                        <input v-model="form_user.street_number" class="form-input" type="street_number" name="street_number" id="street_number" />
                      </div>
                    </div>
                  </div>

                  <div class="row row-gutter-20">
                    <div class="col-8">
                      <div class="form-wrap">
                        <label for="zip_code">{{ $t("global.postalcode") }}</label>
                        <input v-model="form_user.zip_code" class="form-input" type="zip_code" name="zip_code" id="zip_code" />
                      </div>
                    </div>
                    <div class="col-16">
                      <div class="form-wrap">
                        <label for="city">{{ $t("global.city") }}</label>
                        <input v-model="form_user.city" class="form-input" type="city" name="city" id="city" />
                      </div>
                    </div>
                  </div>

                  <div v-if="form_user.company" class="form-wrap">
                    <label for="city">{{ $t("global.vatid") }}</label>
                    <input v-model="form_user.vat_id" class="form-input" type="city" name="city" id="city" />
                  </div>

                  <p style="margin: 0; text-align: right">
                    <input type="submit" :value="$t('profil.button')" class="button button-ci button-round button-100" />
                  </p>
                </form>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("profil.editImage") }}</h3>
            </header>
            <div class="content-body">
              <div v-if="loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <form @submit="update_avatar" class="form profile-pic" method="post">
                  <div class="row row-gutter-20">
                    <div class="col-16">
                      <div class="form-wrap">
                        <label>{{ $t("profil.profilImage") }}</label>
                        <input type="file" @change="previewFile" style="color: black" />
                        <p style="color: var(--ci-danger)" v-if="uploadError">{{ $t("profil.uploadError") }}</p>
                      </div>
                    </div>
                    <div class="col-8" style="text-align: right">
                      <img :src="user.avatar" height="100" width="100" />
                    </div>
                  </div>

                  <p style="margin-bottom: 0; text-align: right">
                    <input type="submit" :value="$t('profil.uploadButton')" class="button button-ci button-round button-100" />
                  </p>
                </form>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("profil.industryTitle") }}</h3>
            </header>
            <div class="content-body">
              <div v-if="loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <p style="margin: 0 0 20px 0">{{ $t("profil.industryDescription") }}</p>
                <p style="color: var(--ci-danger)" v-if="industry_message">{{ industry_message }}</p>
                <form @submit="update_industry" class="form" method="post">
                  <div class="form-wrap select-wrap">
                    <label for="industry">{{ $t("profil.chooseIndustry") }}</label>
                    <select v-model="industry" v-on:change="onChange" class="select-wrap">
                      <option v-for="ind in industries" v-bind:value="ind.id" v-bind:key="ind.id">
                        {{ ind.name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-wrap select-wrap" v-if="industry">
                    <label for="subIndustry">{{ $t("profil.chooseSubIndustry") }}</label>
                    <select v-model="subIndustry" class="select-wrap">
                      <option v-for="sub in subIndustries" v-bind:value="sub.id" v-bind:key="sub.id">
                        {{ sub.name }}
                      </option>
                    </select>
                  </div>

                  <p style="margin-bottom: 0; text-align: right">
                    <input type="submit" :value="$t('profil.saveIndustryButton')" class="button button-ci button-round button-100" />
                  </p>
                </form>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("profil.partnerTitle") }}</h3>
            </header>
            <div class="content-body">
              <div v-if="loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>
                <div class="partner-text" v-html="$t('profil.partnerDescription')"></div>
                <input type="text" :value="this.link" id="referalLink" class="affiliate-well" style="margin-bottom: 10px" />
                <div class="tooltip">
                  <button v-on:click="copyLink" v-on:mouseout="tooltipOut" class="button button-ci button-round">
                    <span class="tooltiptext" id="myTooltip">{{ tooltip }}</span>
                    {{ $t("profil.partnerLink") }}
                  </button>
                </div>
                <h3 style="margin-top: 20px; font-weight: 600">{{ $t("profil.partnerLinkShare") }}</h3>
                <br />
                <div class="social-share">
                  <a
                    :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + this.link"
                    target="_blank"
                    class="button button-social button-linkedin button-round"
                  >
                    <img src="@/assets/icons/social/linkedin.svg" /><span>LinkedIn</span>
                  </a>
                  <a
                    :href="'https://www.facebook.com/sharer/sharer.php?u=' + this.link"
                    target="_blank"
                    class="button button-social button-facebook button-round"
                  >
                    <img src="@/assets/icons/social/facebook.svg" /><span>Facebook</span>
                  </a>
                  <a :href="'https://twitter.com/share?url=' + this.link" target="_blank" class="button button-social button-twitter button-round">
                    <img src="@/assets/icons/social/twitter.svg" /><span>Twitter</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ $t("global.privacy") }}</h3>
            </header>
            <div class="content-body">
              <p>
                {{ $t("profil.privacyDescription") }}
                <span>
                  <a :href="$t('link.privacy')" target="_blank" style="font-weight: 600; color: #444; text-decoration: underline">{{
                    $t("global.website")
                  }}</a> </span
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";

export default {
  name: "user",
  data() {
    return {
      errors: [],
      uploadError: false,
      loading: true,
      industries: [],
      industry: "",
      subIndustries: [],
      subIndustry: "",
      form_user: {
        company: "",
        first_name: "",
        last_name: "",
        street_name: "",
        street_number: "",
        zip_code: "",
        city: "",
        vat_id: "",
      },
      user: {
        company: "",
        first_name: "",
        last_name: "",
        street_name: "",
        street_number: "",
      },
      link: "",
      tooltip: this.$t("profil.partnerLinkCopyNow"),
      industry_message: "",
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/industries", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.industries = response.data.industries;
          this.fetch_user();
        });
    },
    fetch_user() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.loading = false;
          this.user = response.data.user;
          this.subIndustry = response.data.user.industry_id;
          // check if new industries set
          if (this.industries.flatMap((f) => f.industries).find((x) => x.id === this.subIndustry)) {
            // get parent industry id
            this.industry = this.industries.flatMap((f) => f.industries).find((x) => x.id === this.subIndustry).industry_id;
            // get current subindustries based on parent id
            this.subIndustries = this.industries.find((x) => x.id === this.industry).industries;
          } else {
            this.industry_message = this.$t("profil.industryError");
          }
          this.form_user.company = response.data.user.company;
          this.form_user.first_name = response.data.user.first_name;
          this.form_user.last_name = response.data.user.last_name;
          this.form_user.street_name = response.data.user.street_name;
          this.form_user.street_number = response.data.user.street_number;
          this.form_user.zip_code = response.data.user.zip_code;
          this.form_user.city = response.data.user.city;
          this.form_user.vat_id = response.data.user.vat_id;
          this.link = this.$t("link.website") + "?ref=" + response.data.user.uuid;
        });
    },
    update_user: function (e) {
      e.preventDefault();

      this.form_user.industry_id = this.subIndustry;

      axios
        .patch(process.env.VUE_APP_BASE_API + "/user", this.form_user, {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.changeSuccess"),
          });
          this.fetch_user();
        })
        .catch((error) => {
          console.log(error);
            this.$notify({
              group: "notification",
              type: "error",
              text: this.$t("notification.errorValidation"),
            });
          /*
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
          */
        });
    },
    update_avatar: function (e) {
      e.preventDefault();

      if (this.temp_avatar == null) {
        this.uploadError = true;
      } else {
        var form_data = new FormData();
        form_data.append("avatar", this.temp_avatar);
        this.uploadError = false;

        axios
          .patch(process.env.VUE_APP_BASE_API + "/user", form_data, {
            headers: { Authorization: store.getters.getToken },
          })
          .then((response) => {
            console.log(response);
            this.$notify({
              group: "notification",
              type: "success",
              text: this.$t("notification.changeSuccess"),
            });
            this.fetch_user();
          })
          .catch((error) => {
            error.response.data.errors.forEach((value) => {
              this.$notify({
                group: "notification",
                type: "error",
                text: value,
              });
            });
          });
      }
    },
    previewFile(event) {
      this.temp_avatar = event.target.files[0];
    },
    update_industry(e) {
      e.preventDefault();

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/user",
          {
            industry_id: this.subIndustry,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.fetch_user();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    copyLink() {
      var copyText = document.getElementById("referalLink");

      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);

      this.tooltip = this.$t("profil.partnerLinkCopy");
    },
    tooltipOut() {
      this.tooltip = this.$t("profil.partnerLinkCopyNow");
    },
    onChange: function () {
      this.subIndustries = this.industries.find((x) => x.id === this.industry).industries;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.affiliate-well {
  margin-right: 10px;
  min-width: 220px;
}

.button-social {
  margin-right: 10px;
  margin-bottom: 10px;

  span {
    color: white;
    font-weight: bold;
  }

  img {
    height: 18px;
    display: inline;
  }
}

.button-linkedin {
  background: #0a66c2;
}

.button-twitter {
  background: #1d9bf0;
}

.button-facebook {
  background: #1877f2;
}

.partner-text {
  margin-bottom: 20px;

  > p {
    margin-top: 0;
  }

  h3 {
    font-weight: 600;
  }
}
</style>
